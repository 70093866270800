<template>
    <WaitingMobile :classify="2" v-if="isMobileAgent()" />
    <WaitingDesktop :classify="2" v-else />
</template>
  
<script>
export default {
    components: {
        WaitingDesktop: () => import('@/components/material/WaitingDesktop'),
        WaitingMobile: () => import('@/components/material/WaitingMobile'),
    },
    name: "ReceiptNormal",
    methods: {

    }
}
</script>
  
<style scoped></style>
  